<template>
  <div id="newsContent">
    <v-toolbar flat>
      <v-toolbar-title>
        <v-btn rounded @click="$router.go(-1)" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <span v-if="isEdit">お知らせ編集</span>
        <span v-else>お知らせ詳細</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-container class="">
      <v-card v-if="isEdit">
        <v-form ref="form" v-model="form.valid">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field label="タイトル" :value="form.subject" :rules="form.subject_rule" @change="value => form.subject = value"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea label="本文" :value="form.content" :rules="form.content_rule" @change="value => form.content = value" rows="10" auto-grow outlined></v-textarea>
              </v-col>
              <v-col>
                <!-- <v-select :items="releaseFlags" item-text="name" label="公開状況" item-value="flag" @change="value => form.release_flag = value" v-model="flagSelected"></v-select> -->
                <v-switch v-model="form.release_flag" label="公開・非公開" :false-value="2" :true-value="1" hide-details="" class="mt-0"></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="isEdit = !isEdit" text>キャンセル</v-btn>
            <v-btn @click="updateDataWithAPI" color="primary"><v-icon>mdi-check</v-icon>変更を保存</v-btn>
            <v-spacer />

            <!-- 確認用 オリジナルダイアログ -->
            <confirm-dialog v-if="isEdit" btnTitle = "削除" @accept="deleteDataWithAPI">
              <template v-slot:title>このお知らせを削除しますか？</template>
              <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
              <template v-slot:activator="{ on }">
              <v-btn icon @click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
              </template>
            </confirm-dialog>

          </v-card-actions>
          <v-overlay :value="isLoading" absolute>
          <v-progress-circular indeterminate size="64" />
        </v-overlay>
        </v-form>
      </v-card>

      <v-card v-if="!isEdit">
        <v-card-title>
          <v-chip small v-if="news.release_flag == 1" color="primary" class="d-block mr-2">公開</v-chip>
          <v-chip small v-else class="d-block mr-2">非公開</v-chip>
          {{ news.subject }}
          <v-spacer />
          <v-btn @click="isEdit = !isEdit" icon><v-icon small>mdi-pencil</v-icon></v-btn>
        </v-card-title>
        <!-- <v-card-subtitle></v-card-subtitle> -->
        <!-- <v-card-subtitle></v-card-subtitle> -->
        <v-divider />
        <v-card-text>
              <!-- <div style="white-space: pre-line;">
                <span v-text="news.content" v-linkified></span>
              </div> -->
              <pre v-html="news.content" v-linkified></pre>
              <!-- <h4 class="mt-2">公開状況</h4>
              {{ news.release_flag == 1 ? '公開' : '非公開' }} -->

      </v-card-text>
      <!-- <v-divider></v-divider> -->

      <v-card-actions>
        <v-spacer/>
        {{ news.create_at | moment("YYYY/MM/DD") }}
      </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
export default {
  components: { ConfirmDialog },
  name: 'newsContent',
  data: function () {
    return {
      isEdit: false,
      isLoading: false,
      news: {},
      form: {
        valid: true,
        subject_rule: [
          v => !!v || 'タイトルは必ず入力してください'
        ],
        content_rule: [
          v => !!v || 'コンテンツは必ず入力してください'
        ]
      },
      flagSelected: {},
      releaseFlags: [
        {
          name: '公開',
          flag: 1
        },
        {
          name: '非公開',
          flag: 2
        }
      ]
    }
  },
  methods: {
    readDataFromAPI: function () {
      const _this = this
      const id = Number(_this.$route.params.id)
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/news/${id}`)
        .then(res => {
          _this.news = res.data
          _this.flagSelected = {
            name: _this.news.release_flag === 1 ? '公開' : '非公開',
            flag: _this.news.release_flag
          }
          _this.form.subject = _this.news.subject
          _this.form.content = _this.news.content
          _this.form.release_flag = _this.news.release_flag
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteDataWithAPI: function () {
      const _this = this
      const id = Number(_this.$route.params.id)
      _this.isLoading = true
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/news/${id}`)
        .then(res => {
          _this.$store.commit('setFlashSuccess', 'お知らせを削除しました。')
          _this.$router.push({ name: 'admin:news_index' })
          _this.isLoading = false
        })
        .catch(err => {
          _this.$store.commit('setFlashError', '削除することができませんでした。')
          _this.isLoading = false
          console.log(err)
        })
    },
    updateDataWithAPI: function () {
      const _this = this
      const id = Number(_this.$route.params.id)
      if (_this.$refs.form.validate()) {
        _this.isLoading = true
        axios.put(`${process.env.VUE_APP_TOKOTON_API_URL}/api/news/${id}`, _this.form)
          .then(res => {
            _this.$store.commit('setFlashSuccess', 'お知らせを編集しました。')
            _this.isLoading = false
            _this.news.subject = _this.form.subject
            _this.news.content = _this.form.content
            _this.news.release_flag = _this.form.release_flag
            _this.flagSelected = {
              name: _this.news.release_flag === 1 ? '公開' : '非公開',
              flag: _this.news.release_flag
            }
            _this.isEdit = false
            _this.close()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
            _this.isLoading = false
            _this.isEdit = false
            console.log(err)
            _this.close()
          })
      }
    },
    close: function () {
      this.$emit('close')
    }
  },
  created: function () {
    this.readDataFromAPI()
  }
}
</script>
